<template>
    <el-main style="margin-top: 30px;">
        <el-row :gutter="20" type="flex" justify="center">
            <el-col :span="20">
                <div class="grid-content title">
                    <img :src="installAppIcon" />{{ installAppName }}
                </div>
                <div class="grid-content bg-purple">
                    <el-form ref="formData" :model="formData" :rules="rules" size="medium" label-width="100px">
                        <div style="display:flex">
                            <el-input v-model="formData.authcode" placeholder="请输入授权码" clearable   />
                            <el-button type="primary" @click="enterAvailable" v-cloak> 确认 </el-button>
                        </div>
                    </el-form>
                </div>
                <ActiveCode @close-draw="closeDraw" :appName = "appName" :drawer="drawer" :redeemUsedAt="redeemUsedAt" :redeemStatus="redeemStatus" :authcode="authcode" :tfInfo="tfInfo"></ActiveCode>
            </el-col>
        </el-row>
    </el-main>
</template>
<script>
import ActiveCode from '@/components/ActiveCode.vue';
import {getAppInfo,activateApp} from "@/utils/request";
export default {
    components: {
        ActiveCode
    },
    data() {
        return {
            formData: {
                authcode: "",
                enter: undefined,
            },
            rules: {
                authcode: [{ required: true, message: '请输入授权码', trigger: 'blur' }],
            },
            installAppName:"不存在APP",
            installAppIcon:"",
            appName:"",
            drawer:false,
            redeemStatus: "",
            authcode:"",
            redeemUsedAt:"",
            tfInfo: {
                tf1_icon:'',
                tf1_link:'',
                tf1_name:'',
                tf2_icon:'',
                tf2_link:'',
                tf2_name:'',
                tf3_icon:'',
                tf3_link:'',
                tf3_name:'',
                active_time:0,
            },
        }
    },
    methods: {
        initApp: function() {},
        closeDraw: function() {
            this.drawer = false
        },
        enterAvailable: function() {
            activateApp(this)
        },

    },
    mounted() {
        this.appName = this.$route.params.id
        getAppInfo(this)
    }
}
</script>
<style src="@/assets/css/index.css"></style>